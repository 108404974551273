import React, { Component } from 'react';
import ContentArea from './ContentArea';
import { HashRouter } from 'react-router-dom';

class main extends Component {
    state = {  }
    render() { 
        return ( 
        <HashRouter>
                <ContentArea/>
        </HashRouter>
        );
    }
}
 
export default main;   
            
