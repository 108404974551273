import React, { Component } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  ListGroupItem,
  ListGroup,
  Container,
  Row,
  Col,
  Input,
  Alert,
  FormGroup,
} from "reactstrap";
import Joi from "joi-browser";
import ExamplesNavbar from "./Navbar";
import Footer from "./Footer";
import axios from "axios";

class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  state = {
    phoneNumberAlert: false,
    phoneNumber: "",
    ctaSubmitted: false,
    errors: {
      phoneNumber: "",
    },
  };
  schema = {
    phoneNumber: Joi.string().length(10).required().label("Phone"),
  };
  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else errors[input.name] = "";
    const errorlen = errorMessage;
    this.setState({
      [input.name]: input.value,
      errors,
      errorlen,
    });
  };
  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    if (!schema[name]) {
      return "";
    }
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : ""; //chnages here 1 of else part in strinng to null
  };
  validate = () => {
    const options = { abortEarly: false };
    const data = {
      phoneNumber: this.state.phoneNumber,
    };
    const { error } = Joi.validate(data, this.schema, options);
    if (!error) return null;
    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };
  componentDidMount = () => {
    document.body.classList.toggle("landing-page");
    window.scroll({
      top: 0,
      // left: 100,
      behavior: "smooth",
    });
  };
  componentWillUnmount = () => {
    document.body.classList.toggle("landing-page");
  };
  scrollToMyRef = () => {
    window.scrollTo(0, this.myRef.current.offsetTop - 70);
  };
  handleCTA = () => {
    const errors = this.validate();

    if (errors) {
      this.setState({
        errors,
      });
      return;
    }
    const API_PATH = "https://ecomguru.in/api/cta.php";
    axios
      .post(API_PATH, {
        phone: this.state.phoneNumber,
      })
      .then((result) => {
        console.log(result);
        this.setState({
          mailSent: result.data.sent,
          ctaSubmitted: true,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ error: error.message });
      });
  };
  render() {
    return (
      <>
        <ExamplesNavbar />
        <div>
          {/* CTA and Hero */}
          <div className="page-header">
            <img
              alt="..."
              className="path2"
              src={
                "https://res.cloudinary.com/dimp9gtpg/image/upload/v1619102225/ecomguru/path2_zsgmx3.png"
              }
            />

            <div className="content-center">
              {/* <Container> */}
              <Row className="row-grid justify-content-between align-items-center text-left ">
                <Col lg="6" md="6" sm="12" className="order-2 order-md-1">
                  <Container>
                    <h1 className="text-white">
                      We can Help You to Grow
                      {/* <br /> */}
                      <span className="text-warning"> Your Business.</span>
                      {/* <br /> */}
                      {/* online */}
                    </h1>
                    <p className="text-white mb-2">
                      As we have moved towards a new decade with transforming
                      business challenges due to the Covid-19 pandemic, You
                      should also upgrade your business with future-ready
                      resources to stay in the market with making a difference
                      to serve people better. 
                    </p>

                    <div className="btn-wrapper mt-4">
                      <div className="button-container ">
                        <Row
                          className={this.state.ctaSubmitted ? "d-none" : ""}
                        >
                          <Col xs="10">
                            <FormGroup>
                              <Input
                                className=""
                                name="phoneNumber"
                                tabIndex="1"
                                placeholder="Get a Call from our side"
                                type="number"
                                // max="100"
                                autoComplete="off"
                                value={this.state.phoneNumber}
                                onChange={this.handleChange}
                                // pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                              />
                            </FormGroup>
                            <label class="text-danger">
                              {this.state.errors.phoneNumber}
                            </label>
                          </Col>
                          <Col xs="2">
                            <Button
                              className="btn-success btn-icon btn-round "
                              color="default"
                              // href="#pablo"
                              tabIndex="2"
                              disabled={this.state.errors.phoneNumber}
                              onClick={this.handleCTA}
                            >
                              <i className="tim-icons icon-send" />
                            </Button>
                          </Col>
                        </Row>
                        <Alert
                          isOpen={this.state.ctaSubmitted}
                          color="success"
                          sizes="sm"
                          style={{ padding: "4px" }}
                          className="text-center"
                        >
                          Congratulation you did it !!
                        </Alert>
                        {/* <Button
                      className="btn btn-primary "
                      color="default"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      Not Now
                    </Button>
                    <Button
                      className="btn btn-success"
                      color="default"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      Yes Sure
                    </Button> */}
                      </div>
                      <div className="btn-wrapper mb-1">
                        <Button
                          className="btn-link"
                          color="success"
                          tabIndex="3"
                          onClick={this.scrollToMyRef}
                          // size="xs"
                        >
                          <p className="category text-success d-inline">
                            Know more
                            <i className="tim-icons icon-minimal-right" />
                          </p>
                        </Button>
                      </div>
                    </div>
                  </Container>
                </Col>
                <Col lg="6" md="6" sm="12" className="order-1 order-md-2">
                  <img
                    alt="..."
                    className="img-fluid"
                    // style={{width:"450px"}}
                    src={
                      "https://res.cloudinary.com/dimp9gtpg/image/upload/v1619102221/ecomguru/main_lh53kc.png"
                    }
                  />
                </Col>
              </Row>

              {/* </Container> */}
            </div>
          </div>

          {/* Who Exactly we are */}
          <section id="what" className="section section-lg " ref={this.myRef}>
            {/* <section className="section"> */}
            <img
              alt="..."
              className="path"
              src={
                "https://res.cloudinary.com/dimp9gtpg/image/upload/v1619102226/ecomguru/path4_d9xdft.png"
              }
            />
            <Container>
              <Row className="row-grid justify-content-between">
                <Col className="mt-lg-5 order-2 order-sm-2" md="6">
                  <Row>
                    <Col className="px-2 py-2" lg="6" sm="12">
                      <Card className="card-stats">
                        <CardBody>
                          <Row>
                            <Col md="4" xs="5">
                              <div className="icon-big text-center icon-warning">
                                <i className="tim-icons icon-bulb-63 text-warning" />
                              </div>
                            </Col>
                            <Col md="8" xs="7">
                              <div className="numbers">
                                <CardTitle tag="p">50+</CardTitle>
                                <p />
                                <p className="card-category">
                                  Business Solutions
                                </p>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col className="px-2 py-2" lg="6" sm="12">
                      <Card className="card-stats upper bg-default">
                        <CardBody>
                          <Row>
                            <Col md="4" xs="5">
                              <div className="icon-big text-center icon-warning">
                                <i className="tim-icons icon-coins text-white" />
                              </div>
                            </Col>
                            <Col md="8" xs="7">
                              <div className="numbers">
                                <CardTitle tag="p">3,653</CardTitle>
                                <p />
                                <p className="card-category">Cost-efficient</p>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="px-2 py-2" lg="6" sm="12">
                      <Card className="card-stats">
                        <CardBody>
                          <Row>
                            <Col md="4" xs="5">
                              <div className="icon-big text-center icon-warning">
                                <i className="fas fa-handshake text-info" />
                              </div>
                            </Col>
                            <Col md="8" xs="7">
                              <div className="numbers">
                                <CardTitle tag="p">593</CardTitle>
                                <p />
                                <p className="card-category">Additional</p>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col className="px-2 py-2" lg="6" sm="12">
                      <Card className="card-stats">
                        <CardBody>
                          <Row>
                            <Col md="4" xs="5">
                              <div className="icon-big text-center icon-warning">
                                <i className="tim-icons icon-credit-card text-success" />
                              </div>
                            </Col>
                            <Col md="8" xs="7">
                              <div className="numbers">
                                <CardTitle tag="p">100+</CardTitle>
                                <p />
                                <p className="card-category">PG Methods</p>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
                <Col md="6" className="order-1 order-sm-1">
                  <div className="pl-md-5">
                    <h1>
                      {/* <br /> */}
                      What Exactly we will do to help you grow
                      <br></br>
                      <span className="text-success">
                        {" "}
                        that's the real question.
                      </span>
                    </h1>
                    <p>
                      Our professional Engineers who are Heavily experienced in
                      their field will consult you to discuss your business
                      requriments and market demand, the procedure also includes
                      the procedure to find perfect IT solutions for you
                      business, the process keep going in a very fluent manner.
                    </p>
                    <br />
                    <p>
                      every single step will be followed under custom tailored
                      designed timeline for your business requirements "&"
                      needs.
                    </p>
                    <br />
                    {/* <a
                      className="font-weight-bold text-info mt-5"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      Show all{" "}
                      <i className="tim-icons icon-minimal-right text-info" />
                    </a> */}
                  </div>
                </Col>
              </Row>
            </Container>
            {/* </section> */}
          </section>

          {/* How Can we help you */}
          <section className="section section-lg">
            <img
              alt="..."
              className="path"
              src={
                "https://res.cloudinary.com/dimp9gtpg/image/upload/v1619102226/ecomguru/path4_d9xdft.png"
              }
            />
            <img
              alt="..."
              className="path2"
              src={
                "https://res.cloudinary.com/dimp9gtpg/image/upload/v1619102226/ecomguru/path5_eo27ph.png"
              }
            />
            <img
              alt="..."
              className="path3"
              src={
                "https://res.cloudinary.com/dimp9gtpg/image/upload/v1619102225/ecomguru/path2_zsgmx3.png"
              }
            />
            <Container>
              <Row className="justify-content-center">
                <Col lg="12">
                  <h1 className="text-center">How Can we help you</h1>
                  <Row className="row-grid justify-content-center">
                    <Col lg="3">
                      <div className="info">
                        <div className="icon icon-warning">
                          <i className="tim-icons icon-chart-pie-36" />
                        </div>
                        <h4 className="info-title">Consultation</h4>
                        <hr className="line-warning" />
                        <p>
                          Before starting any work on any project, it is very
                          important to know how this project will make the life
                          of its user and owner better. We pay more attention to
                          these points plus we make that project ahead with our
                          experience and passion
                        </p>
                      </div>
                    </Col>

                    <Col lg="3">
                      <div className="info">
                        <div className="icon icon-primary">
                          <i className="tim-icons icon-money-coins " />
                        </div>
                        <h4 className="info-title">Startup Friendly</h4>
                        <hr className="line-primary" />
                        <p>
                          Divide details about your work into parts. Write a few
                          lines about each one. A paragraph describing a feature
                          will.
                        </p>
                      </div>
                    </Col>

                    <Col lg="3">
                      <div className="info">
                        <div className="icon icon-success">
                          <i className="tim-icons icon-single-02" />
                        </div>
                        <h4 className="info-title">Experienced Team</h4>
                        <hr className="line-success" />
                        <p>
                          Divide details about your product or agency work into
                          parts. Write a few lines about each one. A paragraph
                          describing be enough.
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>

          {/* How we do things */}
          <section className="section section-lg section-safe">
            <img
              alt="..."
              className="path"
              src={
                "https://res.cloudinary.com/dimp9gtpg/image/upload/v1619102226/ecomguru/path5_eo27ph.png"
              }
            />
            <Container>
              <Row className="row-grid justify-content-between">
                <Col className="order-2 order-sm-2" md="5">
                  <img
                    alt="..."
                    className="img-fluid floating"
                    src={
                      "https://res.cloudinary.com/dimp9gtpg/image/upload/v1619102217/ecomguru/dothings_zuclli.png"
                    }
                  />
                  <Card className="card-stats bg-danger">
                    <CardBody>
                      <div className="justify-content-center">
                        <div className="numbers">
                          <CardTitle tag="p">100%</CardTitle>
                          <p className="card-category text-white">Responsive</p>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                  <Card className="card-stats bg-info">
                    <CardBody>
                      <div className="justify-content-center">
                        <div className="numbers">
                          <CardTitle tag="p">24</CardTitle>
                          <p className="card-category text-white">
                            Satisfied customers
                          </p>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                  <Card className="card-stats bg-default">
                    <CardBody>
                      <div className="justify-content-center">
                        <div className="numbers">
                          <CardTitle tag="p">MERN</CardTitle>
                          <p className="card-category text-white">Stack</p>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col className="order-1 order-sm-1" md="6">
                  <div className="px-md-5">
                    <hr className="line-success" />
                    <h1>How we do things</h1>
                    <p>
                      The design system comes with three pre-built pages to help
                      you get started faster. You can change the text and images
                      and you're good to go.
                    </p>
                    <ul className="list-unstyled mt-5">
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div className="icon icon-success mb-2">
                            <i className="tim-icons icon-vector" />
                          </div>
                          <div className="ml-3">
                            <h6>Carefully crafted components</h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div className="icon icon-success mb-2">
                            <i className="tim-icons icon-tap-02" />
                          </div>
                          <div className="ml-3">
                            <h6>Amazing page examples</h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div className="icon icon-success mb-2">
                            <i className="tim-icons icon-single-02" />
                          </div>
                          <div className="ml-3">
                            <h6>Super friendly support team</h6>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>

          {/* What services we provide */}
          <section className="section section-lg section-coins">
            <img
              alt="..."
              className="path"
              src={
                "https://res.cloudinary.com/dimp9gtpg/image/upload/v1619102226/ecomguru/path3_zmn8fg.png"
              }
            />
            <Container>
              <Row>
                <Col md="4">
                  <hr className="line-info" />
                  <h1>
                    What Services <span className="text-info">we provide</span>
                  </h1>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <Card className="card-coin card-plain">
                    <CardHeader>
                      <img
                        alt="..."
                        className="img-center img-fluid"
                        src={
                          "https://res.cloudinary.com/dimp9gtpg/image/upload/v1620975903/ecomguru/programming_wi49ho.svg"
                        }
                      />
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col className="text-center" md="12">
                          <h4 className="text-uppercase">
                            Web Application Development
                          </h4>
                          <hr className="line-primary" />
                        </Col>
                      </Row>
                      <Row>
                        <ListGroup>
                          <ListGroupItem>
                            Dynamic Business Solutions
                          </ListGroupItem>
                          <ListGroupItem>Dynamic Web Apps</ListGroupItem>
                        </ListGroup>
                      </Row>
                    </CardBody>
                    <CardFooter className="text-center">
                      <Button
                        href="#Services"
                        className="btn-simple"
                        color="primary"
                      >
                        Know More
                      </Button>
                    </CardFooter>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-coin card-plain">
                    <CardHeader>
                      <img
                        alt="..."
                        className="img-center img-fluid"
                        src={
                          "https://res.cloudinary.com/dimp9gtpg/image/upload/v1620975903/ecomguru/smartphone_rvogb1.svg"
                        }
                      />
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col className="text-center" md="12">
                          <h4 className="text-uppercase">
                            Mobile App Development
                          </h4>
                          {/* <span>Plan</span> */}
                          <hr className="line-success" />
                        </Col>
                      </Row>
                      <Row>
                        <ListGroup>
                          <ListGroupItem>Hybrid App</ListGroupItem>
                          <ListGroupItem>Android & Iphone App</ListGroupItem>
                        </ListGroup>
                      </Row>
                    </CardBody>
                    <CardFooter className="text-center">
                      <Button
                        href="#Services"
                        className="btn-simple"
                        color="success"
                      >
                        Know More
                      </Button>
                    </CardFooter>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-coin card-plain">
                    <CardHeader>
                      <img
                        alt="..."
                        className="img-center img-fluid"
                        src={
                          "https://res.cloudinary.com/dimp9gtpg/image/upload/v1620975903/ecomguru/digital-marketing_iujfvz.svg"
                        }
                      />
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col className="text-center" md="12">
                          <h4 className="text-uppercase">
                            Digital Marketing Solutions
                          </h4>
                          {/* <span>Plan</span> */}
                          <hr className="line-info" />
                        </Col>
                      </Row>
                      <Row>
                        <ListGroup>
                          <ListGroupItem>SEO SEM SMM</ListGroupItem>
                          <ListGroupItem>FB & IG Ads</ListGroupItem>
                        </ListGroup>
                      </Row>
                    </CardBody>
                    <CardFooter className="text-center">
                      <Button
                        href="#Services"
                        className="btn-simple"
                        color="info"
                      >
                        Know More
                      </Button>
                    </CardFooter>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
          <Footer />
        </div>
      </>
    );
  }
}

export default LandingPage;
