import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

// core components
import ExamplesNavbar from "./Navbar";
import Footer from "./Footer";
import ProjectComponent from "./ProjectComponent";

const projectsJson = [
  {
    heading: "Admin Dashboard",
    para: "lorem ipsume dutch and the asadfaswere lorem ipsume dutch and the asadfaswere lorem ipsume dutch and the asadfaswere lorem ipsume dutch and the asadfaswere ",
    paraback: "ReactJS",
    img: [
      {
        src: "https://res.cloudinary.com/dimp9gtpg/image/upload/v1621872193/ecomguru/portfolio19_uot1ox.jpg",
        altText: "Slide 1",
        caption: "Slide 1",
        header: "Slide 1 Header",
        key: "1",
      },
      {
        src: "https://res.cloudinary.com/dimp9gtpg/image/upload/v1621872193/ecomguru/portfolio20_vhfjsz.jpg",
        altText: "Slide 2",
        caption: "Slide 2",
        header: "Slide 2 Header",
        key: "2",
      },
      {
        src: "https://res.cloudinary.com/dimp9gtpg/image/upload/v1621872188/ecomguru/portfolio2_mmlmjf.jpg",
        altText: "Slide 3",
        caption: "Slide 3",
        header: "Slide 3 Header",
        key: "3",
      },
    ],
  },
  {
    heading: "Confrence Platform",
    para: "lorem ipsume dutch and the asadfaswere lorem ipsume dutch and the asadfaswere lorem ipsume dutch and the asadfaswere lorem ipsume dutch and the asadfaswere ",
    paraback: "NextJS",
    img: [
      {
        src: "https://res.cloudinary.com/dimp9gtpg/image/upload/v1621872192/ecomguru/portfolio17_w6rzbo.jpg",
        altText: "Slide 1",
        caption: "Slide 1",
        header: "Slide 1 Header",
        key: "1",
      },
      {
        src: "https://res.cloudinary.com/dimp9gtpg/image/upload/v1621872192/ecomguru/portfolio16_eglkpk.jpg",
        altText: "Slide 2",
        caption: "Slide 2",
        header: "Slide 2 Header",
        key: "2",
      },
      {
        src: "https://res.cloudinary.com/dimp9gtpg/image/upload/v1621872191/ecomguru/portfolio18_otsims.jpg",
        altText: "Slide 3",
        caption: "Slide 3",
        header: "Slide 3 Header",
        key: "3",
      },
    ],
  },
  {
    heading: "E-Commerce",
    para: "lorem ipsume dutch and the asadfaswere lorem ipsume dutch and the asadfaswere lorem ipsume dutch and the asadfaswere lorem ipsume dutch and the asadfaswere ",
    paraback: "MERN",
    img: [
      {
        src: "https://res.cloudinary.com/dimp9gtpg/image/upload/v1621872191/ecomguru/portfolio14_xwn4lm.jpg",
        altText: "Slide 1",
        caption: "Slide 1",
        header: "Slide 1 Header",
        key: "1",
      },
      {
        src: "https://res.cloudinary.com/dimp9gtpg/image/upload/v1621872191/ecomguru/portfolio15_iv01tn.jpg",
        altText: "Slide 2",
        caption: "Slide 2",
        header: "Slide 2 Header",
        key: "2",
      },
      {
        src: "https://res.cloudinary.com/dimp9gtpg/image/upload/v1621872188/ecomguru/portfolio8_frxkf2.jpg",
        altText: "Slide 3",
        caption: "Slide 3",
        header: "Slide 3 Header",
        key: "3",
      },
    ],
  },
  {
    heading: "Admin Dashboard",
    para: "lorem ipsume dutch and the asadfaswere lorem ipsume dutch and the asadfaswere lorem ipsume dutch and the asadfaswere lorem ipsume dutch and the asadfaswere ",
    paraback: "BS4",
    img: [
      {
        src: "https://res.cloudinary.com/dimp9gtpg/image/upload/v1621872190/ecomguru/portfolio12_uuemom.jpg",
        altText: "Slide 1",
        caption: "Slide 1",
        header: "Slide 1 Header",
        key: "1",
      },
      {
        src: " https://res.cloudinary.com/dimp9gtpg/image/upload/v1621872187/ecomguru/portfolio6_wzjyu6.jpg",
        altText: "Slide 2",
        caption: "Slide 2",
        header: "Slide 2 Header",
        key: "2",
      },
      // {
      //   src: "https://res.cloudinary.com/dimp9gtpg/image/upload/v1621872191/ecomguru/portfolio9_kmxsxw.jpg",
      //   altText: "Slide 3",
      //   caption: "Slide 3",
      //   header: "Slide 3 Header",
      //   key: "3",
      // },
    ],
  },
  {
    heading: "Ecommerce",
    para: "lorem ipsume dutch and the asadfaswere lorem ipsume dutch and the asadfaswere lorem ipsume dutch and the asadfaswere lorem ipsume dutch and the asadfaswere ",
    paraback: "MEAN",
    img: [
      {
        src: "https://res.cloudinary.com/dimp9gtpg/image/upload/v1621872189/ecomguru/portfolio7_foggvj.jpg",
        altText: "Slide 1",
        caption: "Slide 1",
        header: "Slide 1 Header",
        key: "1",
      },
      {
        src: "https://res.cloudinary.com/dimp9gtpg/image/upload/v1621872189/ecomguru/portfolio10_aymazr.jpg",
        altText: "Slide 2",
        caption: "Slide 2",
        header: "Slide 2 Header",
        key: "2",
      },
      {
        src: "https://res.cloudinary.com/dimp9gtpg/image/upload/v1621872191/ecomguru/portfolio9_kmxsxw.jpg",
        altText: "Slide 3",
        caption: "Slide 3",
        header: "Slide 3 Header",
        key: "3",
      },
    ],
  },
  {
    heading: "Content Marketing",
    para: "lorem ipsume dutch and the asadfaswere lorem ipsume dutch and the asadfaswere lorem ipsume dutch and the asadfaswere lorem ipsume dutch and the asadfaswere ",
    paraback: "SMM",
    img: [
      {
        src: "https://res.cloudinary.com/dimp9gtpg/image/upload/v1622624671/ecomguru/9-min_vst7lt.png",
        altText: "Slide 1",
        caption: "Slide 1",
        header: "Slide 1 Header",
        key: "1",
      },
      {
        src: "https://res.cloudinary.com/dimp9gtpg/image/upload/v1622624669/ecomguru/10-min_tnpocv.png",
        altText: "Slide 2",
        caption: "Slide 2",
        header: "Slide 2 Header",
        key: "2",
      },
      {
        src: "https://res.cloudinary.com/dimp9gtpg/image/upload/v1622624671/ecomguru/4-min_doon0i.png",
        altText: "Slide 3",
        caption: "Slide 3",
        header: "Slide 3 Header",
        key: "3",
      },
      {
        src: "https://res.cloudinary.com/dimp9gtpg/image/upload/v1622624675/ecomguru/26-min_yomjjt.png",
        altText: "Slide 4",
        caption: "Slide 4",
        header: "Slide 4 Header",
        key: "4",
      },
      {
        src: "https://res.cloudinary.com/dimp9gtpg/image/upload/v1622624681/ecomguru/24-min_le6yok.png",
        altText: "Slide 5",
        caption: "Slide 5",
        header: "Slide 5 Header",
        key: "5",
      },
      {
        src: "https://res.cloudinary.com/dimp9gtpg/image/upload/v1622624688/ecomguru/7-min_nyimiq.png",
        altText: "Slide 6",
        caption: "Slide 6",
        header: "Slide 6 Header",
        key: "6",
      },
    ],
  },
];

class ProfilePage extends Component {
  state = {};
  componentDidMount = () => {
    document.body.classList.toggle("profile-page");
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  };
  componentWillUnmount = () => {
    document.body.classList.toggle("profile-page");
  };
  render() {
    return (
      <>
        <ExamplesNavbar />
        <div>
          <div className="page-header">
            <Container className="align-items-center">
              <Row>
                <Col>
                  <h1 className="profile-title text-left">Portfolio</h1>
                  <h5 className="text-on-back">EG</h5>
                  <p className="">
                    Offices parties lasting outward nothing age few resolve.
                    Impression to discretion understood to we interested he
                    excellence. Him remarkably use projection collecting. Going
                    about eat forty world has round miles ffices parties lasting
                    outward nothing age few resolve. Impression to discretion
                    understood to we interested he excellence. Him remarkably
                    use projection collecting. Going about eat forty world has
                    round miles. ffices parties lasting outward nothing age few
                    resolve. Impression to discretion understood to we
                    interested he excellence. Him remarkably use projection
                    collecting. Going about eat forty world has round
                    miles.ffices parties lasting outward nothing age few
                    resolve. Impression to discretion understood to we
                    interested he excellence. Him remarkably use projection
                    collecting. Going about eat forty world has round miles.
                  </p>
                </Col>
              </Row>
            </Container>
          </div>

          {projectsJson.map((proj) => (
            <div className="section">
              <ProjectComponent
                heading={proj.heading}
                para={proj.para}
                paraback={proj.paraback}
                img={proj.img}
              />
            </div>
          ))}

          <Footer />
        </div>
      </>
    );
  }
}

export default ProfilePage;
