
import React, { Component } from 'react';
import {
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ExamplesNavbar from "./Navbar";
import Footer from "./Footer";
import ProjectComponent from  './ProjectComponent';



const projectsJson = [
  {
    heading:'Web Application',
    para:"lorem ipsume dutch and the asadfaswere lorem ipsume dutch and the asadfaswere lorem ipsume dutch and the asadfaswere lorem ipsume dutch and the asadfaswere ",
    paraback:'Cloud',
    img:"https://res.cloudinary.com/dimp9gtpg/image/upload/v1621880475/ecomguru/web-development_piokxj.jpg"
  },
  {
    heading:'Mobile Application',
    para:"lorem ipsume dutch and the asadfaswere lorem ipsume dutch and the asadfaswere lorem ipsume dutch and the asadfaswere lorem ipsume dutch and the asadfaswere ",
    paraback:'Hybrid',
    img:"https://res.cloudinary.com/dimp9gtpg/image/upload/v1621880475/ecomguru/mobile-app_iseurp.jpg"

  },
  {
    heading:'Digital Marketing',
    para:"lorem ipsume dutch and the asadfaswere lorem ipsume dutch and the asadfaswere lorem ipsume dutch and the asadfaswere lorem ipsume dutch and the asadfaswere ",
    paraback:'Leads',
    img:"https://res.cloudinary.com/dimp9gtpg/image/upload/v1621880475/ecomguru/digitalmarketing_ykwgn3.jpg"

  },
]


class ProfilePage extends Component {
  state = {  }
  componentDidMount = () => {
    document.body.classList.toggle("profile-page");
      window.scroll({
        top: 0,
        // left: 100,
        behavior: 'smooth'
      });
  };
  componentWillUnmount = () => {
    document.body.classList.toggle("profile-page");
  };
  render() { 
    return ( 
      <>
      <ExamplesNavbar />
      <div>
        
        <div className="page-header">
          <Container className="align-items-center">
            <Row>
              <Col >
                <h1 className="profile-title text-left">Services</h1>
                <h5 className="text-on-back">EG</h5>
                <p className="">
                  Offices parties lasting outward nothing age few resolve.
                  Impression to discretion understood to we interested he
                  excellence. Him remarkably use projection collecting. Going
                  about eat forty world has round miles,Offices parties lasting outward nothing age few resolve.
                  Impression to discretion understood to we interested he
                  excellence. Him remarkably use projection collecting. Going
                  about eat forty world has round miles. Offices parties lasting outward nothing age few resolve.
                  Impression to discretion understood to we interested he
                  excellence. Him remarkably use projection collecting. Going
                  about eat forty world has round miles.
                  
                </p>
               
              </Col>
              
            </Row>
          </Container>
        </div>
        
          {projectsJson.map(proj=><div className="section">
            <ProjectComponent heading={proj.heading} para={proj.para} paraback={proj.paraback} img={proj.img}/>
          </div>)}
        
        <Footer />
      </div>
    </>
 
     );
  }
}
 
export default ProfilePage;