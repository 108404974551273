import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

export default function ExamplesNavbar() {
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [collapseOut, setCollapseOut] = React.useState("");
  const [color, setColor] = React.useState("navbar-transparent");

  React.useEffect(() => {
    window.addEventListener("scroll", changeColor);
    return function cleanup() {
      window.removeEventListener("scroll", changeColor);
    };
  }, []);

  const changeColor = () => {
    if (
      document.documentElement.scrollTop > 99 ||
      document.body.scrollTop > 99
    ) {
      setColor("bg-info");
    } else if (
      document.documentElement.scrollTop < 100 ||
      document.body.scrollTop < 100
    ) {
      setColor("navbar-transparent");
    }
  };

  const toggleCollapse = () => {
    document.documentElement.classList.toggle("nav-open");
    setCollapseOpen(!collapseOpen);
  };

  const onCollapseExiting = () => {
    setCollapseOut("collapsing-out");
  };

  const onCollapseExited = () => {
    setCollapseOut("");
  };

  return (
    <Navbar className={"fixed-top " + color} color-on-scroll="100" expand="lg">
      <Container>
        <div className="navbar-translate">
          <NavbarBrand to="/" id="navbar-brand" tag={Link}>
            <span>EG </span>
            EcomGuru
          </NavbarBrand>

          <UncontrolledTooltip placement="bottom" target="navbar-brand">
            Dev. | Train.| Mark.
          </UncontrolledTooltip>

          <button
            aria-expanded={collapseOpen}
            className="navbar-toggler navbar-toggler"
            onClick={toggleCollapse}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>

        <Collapse
          className={"justify-content-end " + collapseOut}
          navbar
          isOpen={collapseOpen}
          onExiting={onCollapseExiting}
          onExited={onCollapseExited}
        >
          <div className="navbar-collapse-header">
            <Row>
              <Col className="collapse-brand" xs="6">
                <a href="/" onClick={(e) => e.preventDefault()}>
                  EG•EcomGuru
                </a>
              </Col>

              <Col className="collapse-close text-right" xs="6">
                <button
                  aria-expanded={collapseOpen}
                  className="navbar-toggler"
                  onClick={toggleCollapse}
                >
                  <i className="tim-icons icon-simple-remove" />
                </button>
              </Col>
            </Row>
          </div>

          <Nav navbar>
            <NavItem>
              <NavLink
                href="#services"
                data-placement="bottom"
                rel="noopener noreferrer"
                // target="_blank"
                title="Services Provided by us"
              >
                <i className=" d-lg-none d-xl-none tim-icons icon-palette" />
                <p className="">Services</p>
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                href="#team"
                data-placement="bottom"
                rel="noopener noreferrer"
                // target="_blank"
                title="Look at our Professional Team"
              >
                <i className=" d-lg-none d-xl-none tim-icons icon-badge" />
                <p className="">Team</p>
              </NavLink>
            </NavItem>
            {/* <NavItem>
              <NavLink
                href="#portfolio"
                data-placement="bottom"
                rel="noopener noreferrer"
                // target="_blank"
                title="Look at our previous completed projects"
              >
                <i className=" d-lg-none d-xl-none tim-icons icon-palette" />
                <p className="">PortFolio</p>
              </NavLink>
            </NavItem> */}

            <NavItem className="p-0">
              <NavLink
                data-placement="bottom"
                href="https://www.instagram.com/ecomguru_in"
                rel="noopener noreferrer"
                target="_blank"
                title="Follow us on Instagram"
              >
                <i className="fab fa-instagram" />
                <p className="d-lg-none d-xl-none">Instagram</p>
              </NavLink>
            </NavItem>
            <NavItem className="p-0">
              <NavLink
                data-placement="bottom"
                href="https://www.facebook.com/ecomguru.dot.in"
                rel="noopener noreferrer"
                target="_blank"
                title="Like us on Facebook"
              >
                <i className="fab fa-facebook-square" />
                <p className="d-lg-none d-xl-none">Facebook</p>
              </NavLink>
            </NavItem>
            <NavItem className="p-0">
              <NavLink
                data-placement="bottom"
                href="https://twitter.com/ecomguru_in"
                rel="noopener noreferrer"
                target="_blank"
                title="Follow us on Twitter"
              >
                <i className="fab fa-twitter" />
                <p className="d-lg-none d-xl-none">Twitter</p>
              </NavLink>
            </NavItem>
            <NavItem className="p-0">
              <NavLink
                data-placement="bottom"
                href="https://linkedin.com/company/ecomguru"
                rel="noopener noreferrer"
                target="_blank"
                title="Connect us with on LinkedIN"
              >
                <i className="fab fa-linkedin-in" />
                <p className="d-lg-none d-xl-none">LinkedIn</p>
              </NavLink>
            </NavItem>
            <NavItem className="p-1">
              <Button
                className="nav-link p-2 d-lg-block"
                color="primary"
                // target="_blank"
                href="#contact"
              >
                <i className="tim-icons icon-spaceship" /> Contact Us
              </Button>
            </NavItem>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}
