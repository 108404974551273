import React, { Component } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  ListGroup,
  ListGroupItem,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

// core components
import ExamplesNavbar from "./Navbar";
import Footer from "./Footer";

class Team extends Component {
  state = {};
  componentDidMount = () => {
    document.body.classList.toggle("profile-page");
    window.scroll({
      top: 0,
      // left: 100,
      behavior: "smooth",
    });
  };
  componentWillUnmount = () => {
    document.body.classList.toggle("profile-page");
  };
  render() {
    return (
      <>
        <ExamplesNavbar />

        <div >
          <div className="page-header mb-5">
            <img
              alt="..."
              className="dots"
              src={
                "https://res.cloudinary.com/dimp9gtpg/image/upload/v1619102217/ecomguru/dots_mm4xbq.png"
              }
            />
            <img
              alt="..."
              className="path"
              src={
                "https://res.cloudinary.com/dimp9gtpg/image/upload/v1619102226/ecomguru/path4_d9xdft.png"
              }
            />

            <Container className="align-items-center mt-5">
              <Row>
                <Col lg="6" md="6">
                  <h1 className="profile-title text-left">
                    Pratish | Full Stack Dev.
                  </h1>
                  <h5 className="text-on-back">Dev.</h5>
                  <p className="profile-description">
                    Offices parties lasting outward nothing age few resolve.
                    Impression to discretion understood to we interested he
                    excellence. Him remarkably use projection collecting. Going
                    about eat forty world has round miles.
                  </p>
                  <div className="btn-wrapper profile pt-3">
                    <Button
                      className="btn-icon btn-round"
                      color="twitter"
                      href="https://twitter.com/creativetim"
                      id="tooltip639225725"
                      target="_blank"
                    >
                      <i className="fab fa-twitter" />
                    </Button>
                    <UncontrolledTooltip delay={0} target="tooltip639225725">
                      Follow us
                    </UncontrolledTooltip>

                    <Button
                      className="btn-icon btn-round"
                      color="facebook"
                      href="https://www.facebook.com/creativetim"
                      id="tooltip982846143"
                      target="_blank"
                    >
                      <i className="fab fa-facebook-square" />
                    </Button>
                    <UncontrolledTooltip delay={0} target="tooltip982846143">
                      Like us
                    </UncontrolledTooltip>
                  </div>
                </Col>
                <Col className="ml-auto mr-auto" lg="4" md="6">
                  <Card className="card-coin card-plain">
                    <CardHeader>
                      <img
                        alt="..."
                        className="img-center img-fluid rounded-circle"
                        src={
                          "https://res.cloudinary.com/dimp9gtpg/image/upload/v1621020469/ecomguru/pratish_vuviqr.jpg"
                        }
                      />
                      <h4 className="title">Lead Developer</h4>
                      <hr className="line-success" />
                    </CardHeader>
                    <CardBody className="text-center" >
                      {/* <Row> */}
                        <Col>
                          <ListGroup>
                            <ListGroupItem>Hybrid App</ListGroupItem>
                            <ListGroupItem>Android & Iphone App</ListGroupItem>
                            <ListGroupItem>Android & Iphone App</ListGroupItem>
                          </ListGroup>
                        </Col>
                      {/* </Row> */}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>

          <div className="section">
            <Container className="align-items-center">
              <Row>
                <Col lg="6" md="6">
                  <h1 className="profile-title text-left">
                    Jay | Digital Marketer
                  </h1>
                  <h5 className="text-on-back">Digi.</h5>
                  <p className="profile-description">
                    Offices parties lasting outward nothing age few resolve.
                    Impression to discretion understood to we interested he
                    excellence. Him remarkably use projection collecting. Going
                    about eat forty world has round miles.
                  </p>
                  <div className="btn-wrapper profile pt-3">
                    <Button
                      className="btn-icon btn-round"
                      color="facebook"
                      href="https://www.facebook.com/creativetim"
                      id="tooltip982846143"
                      target="_blank"
                    >
                      <i className="fab fa-facebook-square" />
                    </Button>
                    <UncontrolledTooltip delay={0} target="tooltip982846143">
                      Like us
                    </UncontrolledTooltip>
                    <Button
                      className="btn-icon btn-round"
                      color="dribbble"
                      href="https://dribbble.com/creativetim"
                      id="tooltip951161185"
                      target="_blank"
                    >
                      <i className="fab fa-instagram" />
                    </Button>
                    <UncontrolledTooltip delay={0} target="tooltip951161185">
                      Follow us
                    </UncontrolledTooltip>
                  </div>
                </Col>
                <Col className="ml-auto mr-auto" lg="4" md="6">
                  <Card className="card-coin card-plain ">
                    <CardHeader>
                      <img
                        alt="..."
                        className="img-center img-fluid rounded-circle"
                        src={
                          "https://res.cloudinary.com/dimp9gtpg/image/upload/v1621020470/ecomguru/sagarr_ei6trb.jpg"
                        }
                      />
                      <h4 className="title">Head of Marketing & Sales</h4>
                      <hr className="line-info" />
                    </CardHeader>
                    <CardBody>
                      <ListGroup>
                        <ListGroupItem>Cras justo odio</ListGroupItem>
                        <ListGroupItem>Dapibus ac facilisis in</ListGroupItem>
                        <ListGroupItem>Vestibulum at eros</ListGroupItem>
                      </ListGroup>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>

          <div className="section">
            <Container className="align-items-center">
              <Row>
                <Col lg="6" md="6">
                  <h1 className="profile-title text-left">Rahul | UI & UX</h1>
                  <h5 className="text-on-back">UI</h5>
                  <p className="profile-description">
                    Offices parties lasting outward nothing age few resolve.
                    Impression to discretion understood to we interested he
                    excellence. Him remarkably use projection collecting. Going
                    about eat forty world has round miles.
                  </p>
                  <div className="btn-wrapper profile pt-3">
                    <Button
                      className="btn-icon btn-round"
                      color="facebook"
                      href="https://www.facebook.com/creativetim"
                      id="tooltip982846143"
                      target="_blank"
                    >
                      <i className="fab fa-facebook-square" />
                    </Button>
                    <UncontrolledTooltip delay={0} target="tooltip982846143">
                      Like us
                    </UncontrolledTooltip>
                    <Button
                      className="btn-icon btn-round"
                      color="dribbble"
                      href="https://instagram.com/creativetim"
                      id="tooltip951161185"
                      target="_blank"
                    >
                      <i className="fab fa-instagram" />
                    </Button>
                    <UncontrolledTooltip delay={0} target="tooltip951161185">
                      Follow us
                    </UncontrolledTooltip>
                  </div>
                </Col>
                <Col className="ml-auto mr-auto" lg="4" md="6">
                  <Card className="card-coin card-plain">
                    <CardHeader>
                      <img
                        alt="..."
                        className="img-center img-fluid rounded-circle"
                        src={
                          "https://res.cloudinary.com/dimp9gtpg/image/upload/v1621020470/ecomguru/rahul_t4bh4t.jpg"
                        }
                      />
                      <h4 className="title">Lead Designer</h4>
                      <hr className="line-warning" />
                    </CardHeader>

                    <CardBody>
                      <ListGroup>
                        <ListGroupItem>Cras justo odio</ListGroupItem>
                        <ListGroupItem>Dapibus ac facilisis in</ListGroupItem>
                        <ListGroupItem>Vestibulum at eros</ListGroupItem>
                      </ListGroup>
                      {/* <Row>
                        <Label sm="3">Pay to</Label>
                        <Col sm="9">
                          <FormGroup>
                            <Input
                              placeholder="e.g. 1Nasd92348hU984353hfid"
                              type="text"
                            />
                            <FormText color="default" tag="span">
                              Please enter a valid address.
                            </FormText>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Label sm="3">Amount</Label>
                        <Col sm="9">
                          <FormGroup>
                            <Input placeholder="1.587" type="text" />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Button
                        className="btn-simple btn-icon btn-round float-right"
                        color="primary"
                        type="submit"
                      >
                        <i className="tim-icons icon-send" />
                      </Button> */}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>

          {/* {teamJson.map((member) => (
            <div className="section">
              <TeamComponent
                name={member.name}
                heading={member.heading}
                para={member.para}
                img={member.img}
              />
            </div>
          ))} */}
          <Footer />
        </div>
      </>
    );
  }
}

export default Team;
