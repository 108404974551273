import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

export default function Footer() {
  return (
    <footer className="footer">
      <Container>
        <Row className="justify-content-md-center">
          {/* <Col md="3"> */}
          {/* </Col> */}
          <Col md="3">
            <h3 className="title">EcomGuru.in</h3>
            <Nav>
              <NavItem>
                <NavLink to="/" tag={Link}>
                  Home
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/Services" tag={Link}>
                  Services
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/portfolio" tag={Link}>
                  Portfolio
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/team" tag={Link}>
                  Our Team
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/contact" tag={Link}>
                  Contact Us
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
          <Col md="4" >
            <h3 className="title">Certfications</h3>
            <Row className="m-2">
              <Col md="3" sm="3" xs="3">
                <img
                  alt="..."
                  className="img-center img-fluid rounded-circle"
                  id="tooltipGoogle"
                  src={
                    "https://res.cloudinary.com/dimp9gtpg/image/upload/v1621065346/ecomguru/google_pnlb9s.png"
                  }
                />

                <UncontrolledTooltip delay={0} target="tooltipGoogle">
                  Google Digital Marketing Certfication
                </UncontrolledTooltip>
              </Col>
              <Col md="3" sm="3" xs="3">
                <img
                  alt="..."
                  className="img-center img-fluid rounded-circle"
                  id="tooltipSemrush"
                  src={
                    "https://res.cloudinary.com/dimp9gtpg/image/upload/v1621075366/ecomguru/semrush_e8qbxm.jpg"
                  }
                />
                <UncontrolledTooltip delay={0} target="tooltipSemrush">
                  Semrush Seo Certfication
                </UncontrolledTooltip>
              </Col>
              <Col md="3" sm="3" xs="3">
                <img
                  alt="..."
                  className="img-center img-fluid rounded-circle"
                  src={
                    "https://res.cloudinary.com/dimp9gtpg/image/upload/v1621065346/ecomguru/LCO_umgvpo.png"
                  }
                  id="tooltipLCO"
                />
                <UncontrolledTooltip delay={0} target="tooltipLCO">
                  Learn Code Online Certfication
                </UncontrolledTooltip>
              </Col>
              <Col md="3" sm="3" xs="3">
                <img
                  alt="..."
                  className="img-center img-fluid rounded-circle"
                  src={
                    "https://res.cloudinary.com/dimp9gtpg/image/upload/v1621074951/ecomguru/udemy_l4mjds.png"
                  }
                  id="tooltipUdemy"
                />
                <UncontrolledTooltip delay={0} target="tooltipUdemy">
                  Udemy Web Development Certfication
                </UncontrolledTooltip>
              </Col>
            </Row>
          </Col>
          <Col md="4">
            <h3 className="title">Follow us on :</h3>
            <div className="btn-wrapper profile">
              <Button
                className="btn-icon btn-neutral btn-round btn-simple"
                color="default"
                href="https://instagram.com/ecomguru_in"
                id="tooltip318450378"
                target="_blank"
              >
                <i className="fab fa-instagram" />
              </Button>
              <UncontrolledTooltip delay={0} target="tooltip318450378">
                Follow us
              </UncontrolledTooltip>

              <Button
                className="btn-icon btn-neutral btn-round btn-simple"
                color="default"
                href="https://www.facebook.com/ecomguru.dot.in"
                id="tooltip230450801"
                target="_blank"
              >
                <i className="fab fa-facebook-square" />
              </Button>
              <UncontrolledTooltip delay={0} target="tooltip230450801">
                Like our Page
              </UncontrolledTooltip>

              <Button
                className="btn-icon btn-neutral btn-round btn-simple"
                color="default"
                href="https://twitter.com/ecomguru_in"
                id="tooltip622135962"
                target="_blank"
              >
                <i className="fab fa-twitter" />
              </Button>
              <UncontrolledTooltip delay={0} target="tooltip622135962">
                Follow us
              </UncontrolledTooltip>

              <Button
                className="btn-icon btn-neutral btn-round btn-simple"
                color="default"
                href="https://linkedin.com/company/ecomguru"
                id="tooltip6221359623"
                target="_blank"
              >
                <i className="fab fa-linkedin-in" />
              </Button>
              <UncontrolledTooltip delay={0} target="tooltip6221359623">
                Connect With Us
              </UncontrolledTooltip>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}
