import Main from './components/main/Main'
// import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
// import {HashRouter} from 'react-router-dom'

import React, { Component } from 'react';
class App extends Component {
  state = {  }
  render() { 
    return ( 
      <Main/>
     );
  }
}
 
export default App;


