import React from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
import axios from "axios";
import Joi from "joi-browser";

// core components
import ExamplesNavbar from "./Navbar";
import Footer from "./Footer";

class ProfilePage extends React.Component {
  state = {
    name: "",
    email: "",
    phone: "",
    subject: "1",
    message: "",
    mailSent: false,
    error: null,
    errors: {
      name: "",
      email: "",
      phone: "",
      message: "",
    },
    errorlen: 1,
  };
  schema = {
    name: Joi.string().required().label("Name"),
    email: Joi.string().email().required().label("Email"),
    phone: Joi.string().length(10).required().label("Phone"),
    message: Joi.string().required().label("Message"),
  };
  componentDidMount = () => {
    window.scroll({
      top: 0,
      // left: 100,
      behavior: "smooth",
    });
  };
  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else errors[input.name] = "";
    const errorlen = errorMessage;
    this.setState({
      [input.name]: input.value,
      errors,
      errorlen,
    });
  };
  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    if (!schema[name]) {
      return "";
    }
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : ""; //chnages here 1 of else part in strinng to null
  };
  validate = () => {
    const options = { abortEarly: false };
    const data = {
      name: this.state.name,
      email: this.state.email,
      phone: this.state.phone,
      message: this.state.message,
    };
    const { error } = Joi.validate(data, this.schema, options);
    if (!error) return null;
    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };
  sendMail = (e) => {
    const errors = this.validate();
    if (errors) {
      this.setState({
        errors,
      });
      return;
    }
    e.preventDefault();
    const API_PATH = "https://ecomguru.in/api/index.php";

    axios
      .post(API_PATH, {
        name: this.state.name,
        message: this.state.message,
        phone: this.state.phone,
        email: this.state.email,
        subject: this.state.subject,
      })
      .then((result) => {
        this.setState({
          mailSent: true,
          name: "",
          email: "",
          phone: "",
          message: "",
        });
      })
      .catch((error) => {
        // console.log(error);
        this.setState({ error: error.message });
      });
  };
  render() {
    return (
      <>
        <ExamplesNavbar />
        <div>
          <section className="section">
            <Container>
              <Row>
                <Col md="6">
                  <Card className="card-plain">
                    <CardHeader>
                      {/* <h1 className="profile-title text-left">asdfa</h1> */}
                      <h5 className="text-on-back">EG</h5>
                    </CardHeader>
                    <CardBody>
                      <Form>
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <label>Your Name</label>
                              <Input
                                placeholder="Enter here.."
                                className="mb-0"
                                type="text"
                                autocomplete="off"
                                name="name"
                                value={this.state.name}
                                onChange={this.handleChange}
                              />
                              <label class="text-danger">
                                {this.state.errors.name}
                              </label>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <label>Email Address*</label>
                              <Input
                                placeholder="name@email.com"
                                className="mb-0"
                                type="email"
                                name="email"
                                autocomplete="off"
                                value={this.state.email}
                                onChange={this.handleChange}
                              />
                              {/* <Alert
                              isOpen={this.state.phoneNumberAlert}
                              color="danger"
                              sizes="sm"
                              style={{ padding: "4px" }}
                            >
                              Please Type Valid Phone Number{" "}
                            </Alert> */}
                              <label class="text-danger">
                                {this.state.errors.email}
                              </label>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <label>Phone*</label>
                              <Input
                                placeholder="+91 9123456789"
                                className="mb-0"
                                type="number"
                                name="phone"
                                autocomplete="off"
                                value={this.state.phone}
                                onChange={this.handleChange}
                              />
                              <label class="text-danger">
                                {this.state.errors.phone}
                              </label>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <label>Subject</label>
                              <Input
                                type="select"
                                name="subject"
                                value={this.state.subject}
                                onChange={this.handleChange}
                                className="mb-0"
                              >
                                <option value="1">Web Application</option>
                                <option value="2">Mobile Application</option>
                                <option value="3">Digital Marketing</option>
                              </Input>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <FormGroup>
                              <label>Message*</label>
                              <Input
                                className="mb-0"
                                placeholder="Hello there!"
                                type="text"
                                name="message"
                                autocomplete="off"
                                value={this.state.message}
                                onChange={this.handleChange}
                              />
                              <label class="text-danger">
                                {this.state.errors.message}
                              </label>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Button
                          className="btn-round float-right"
                          color={this.state.mailSent ? "success" : "primary"}
                          data-placement="right"
                          id="tooltip341148792"
                          type="button"
                          disabled={
                            this.state.mailSent
                              ? true
                              : !this.state.name |
                                !this.state.email |
                                !this.state.phone |
                                !this.state.message
                          }
                          onClick={(e) => this.sendMail(e)}
                        >
                          {this.state.mailSent ? "Sent" : "Send"}
                        </Button>
                        <UncontrolledTooltip
                          delay={0}
                          placement="right"
                          target="tooltip341148792"
                        >
                          Can't wait for your message
                        </UncontrolledTooltip>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
                <Col className="ml-auto" md="4">
                  {/* <div className="info info-horizontal">
                    <div className="icon icon-primary">
                      <i className="tim-icons icon-square-pin" />
                    </div>
                    <div className="description">
                      <h4 className="info-title">Find us at</h4>
                      <p>Indore ( M.P.) India</p>
                    </div>
                  </div> */}
                  <div className="info info-horizontal">
                    <div className="icon icon-primary">
                      <i className="tim-icons icon-email-85" />
                    </div>
                    <div className="description">
                      <h4 className="info-title">Mail us at</h4>
                      <p>
                        <a href="mailto:info@ecomguru.in">info@ecomguru.in</a>
                        <br />
                        For any Business Query
                      </p>
                    </div>
                  </div>
{/*  <div className="info info-horizontal">
                    <div className="icon icon-primary">
                      <i className="tim-icons icon-mobile" />
                    </div>
                    <div className="description">
                      <h4 className="info-title">Give us a ring</h4>
                      <p>
                        Customer Care
                        <br />
                        <a href="tel:+916262555755">+91 6262 555 755</a>
                        <br />
                        Mon - Sat, 10:00-18:00
                      </p>
                    </div>
                  </div>*/}
                </Col>
              </Row>
            </Container>
          </section>
          <Footer />
        </div>
      </>
    );
  }
}

export default ProfilePage;
