import React, { Component } from "react";

import { Container, Row, Col, Button, UncontrolledCarousel } from "reactstrap";

class Project extends Component {
  state = {};
  render() {
    return (
      <Container>
        <Row className="justify-content-between">
          <Col md="6">
            <Row className="justify-content-between align-items-center">
              {console.log(this.props.img)}
              {typeof this.props.img == "object" ? (
                <UncontrolledCarousel items={this.props.img} />
              ) : (
                <img alt=".." src={this.props.img} />
              )}
              {console.log(this.props.img)}
            </Row>
          </Col>
          <Col md="5">
            <h1 className="profile-title text-left">{this.props.heading}</h1>
            <h5 className="text-on-back">{this.props.paraback}</h5>
            <p className="profile-description text-left">{this.props.para}</p>
            <div className="text-center m-5">
              <Button href="#contact" className="btn-simple" color="info">
                Get Quote
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Project;
