import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import Landing from "./Landing";
import Contact from "./Contact";
import Team from "./Team";
import Projects from "./Projects";
import NotFound from "./NotFound";
import Services from "./Services";

class ContentArea extends Component {
  state = {};
  render() {
    return (
      <Switch>
        <Route exact path={"/"} component={Landing} />
        <Route exact path={"/contact"} component={Contact} />
        <Route exact path={"/services"} component={Services} />
        <Route exact path={"/portfolio"} component={Projects} />
        <Route exact path={"/team"} component={Team} />
        <Route path={"*"} component={NotFound} />
      </Switch>
    );
  }
}

export default ContentArea;
