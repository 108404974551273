import React, { Component } from 'react';
import { Button, Row, Col } from "reactstrap";

import ExamplesNavbar from "./Navbar";
import Footer from "./Footer";

// export default function LandingPage() {
//   return (
//    );
// }


class NotFound extends Component {
  state = {  }
  componentDidMount = () => {
    document.body.classList.toggle("profile-page");
    window.scroll({
      top: 0,
      // left: 100,
      behavior: "smooth",
    });
  };
  componentWillUnmount = () => {
    document.body.classList.toggle("profile-page");
  };
  render() { 
    return (  <>
      <ExamplesNavbar />
      <div >
        <div className="page-header">
          <div className="content-center">
            <Row className="row-grid justify-content-between align-items-center text-left">
              <Col lg="6" md="6">
                <h1 className="text-white">
                  Page Not Found
                  <br />
                  <span className="text-white">Error 404 !</span>
                </h1>
                <p className="text-white mb-3">
                  If you think, that the desired page should be here, please
                  contact us
                </p>
                <div className="btn-wrapper mb-3"></div>
                <div className="btn-wrapper">
                  <div className="button-container">
                    <Button
                      className="btn btn-primary "
                      color="default"
                      href="#/contact"
                      // onClick={(e) => e.preventDefault()}
                    >
                      Contact Us
                    </Button>
                  </div>
                </div>
              </Col>
              {/* <Col lg="4" md="5">
                <img
                  alt="..."
                  className="img-fluid"
                  src={
                    "https://res.cloudinary.com/dimp9gtpg/image/upload/v1620976642/ecomguru/404-error_rx4zpi.svg"
                  }
                />
              </Col> */}
            </Row>
          </div>
        </div>
        <Footer />
      </div>
    </>
   );
  }
}
 
export default NotFound;